import http from "../http-common";

class SeatMapService {
  getList(data) {
    return http.post(`/seatmap/list`, data);
  }     
  
  getMapList(data) {
    return http.post(`/seatmap/maplist`, data);
  } 

  getFindPL(data) {
    return http.post(`/seatmap/findpl`, data);
  } 
  
}

export default new SeatMapService();
